@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.container {
  text-align: center;
  width: 1200px;
  height: 680px;
  position: relative;
  @media(max-width: 750px) {
    width: 100%;
  }
}

.header_fixed {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  width: 100%;
  height: 80px;
  // padding-bottom: 20px;
}

.header_box {
  position: relative;
  max-width: 1200px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_left {
  display: flex;
}


.header {
  // flex: 1;
  text-align: left;
  display: inline-block;
  padding-left: 70px;
  a {
    font-family: Poppins;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    color: #000;
    mix-blend-mode: normal;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.launch_button {
  // position: absolute;
  // top: 32px;
  // right: 30px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  width: 170px;
  height: 40px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  border: 0
}
.main_text {
  margin-top: 180px;
}

:global(.subtitle_wrapper) {
  max-width: 734px;
}

.block_reveal1, .block_reveal2 {
  background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
  background-clip: text;
  color: transparent;
  font-size: 80px;
  line-height: 90px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

.block_reveal1::after, .block_reveal2::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}
.block_reveal1::after {
  animation: revealingOut 0.5s forwards;
}
.block_reveal2::after {
  animation: revealingOut 0.5s 0.3s forwards;
}

.submit_button {
  display: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  width: 188px;
  height: 50px;
  border-radius: 27px;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-top: 30px;
  border: 0;
}

/* animations */
@keyframes revealingOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@media(max-width: 750px) {
  .container {
    height: auto;
  }
  .header {
    padding-left: 30px;
  }
  .header_box {
    width: 100%;
    max-width: 375px;
    padding: 0 20px;
    margin: 0 !important;
  }
  .main_text {
    margin-top: 100px;
    margin-bottom: 40px;
  }
  .h5_hide {
    visibility: hidden;
    display: none;
  }
  .submit_button {
    display: inline-block;
  }
  .block_reveal1, .block_reveal2 {
    padding: 0 25px;
    font-size: 40px;
    line-height: 1.2;
  }
}