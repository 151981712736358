@import "../../assets/css/_variables.scss";

a {
  text-decoration: none;
}

li {
  list-style: none;
}
.footer_module {
  width: 100%;
  background: linear-gradient(90deg, #000000 0%, #3A3A3A 98.03%);
  color: #fff;
}

.footer {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  padding: 20px;
}
.left_area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.select_wrap :global(.ant-select-selector) {
  width: 148px;
  border-radius: 30px!important;
  border: 1px solid #F2F2F2!important;
  padding: 0 20px!important;
  height: 43px!important;
  
  :global(.ant-select-selection-item) {
    line-height: 43px!important;
  }
  :global {
    .ant-select-selector {
      background: transparent;
      color: #fff;
    }
  }
}
:global {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,.ant-select-arrow {
    background: transparent;
      color: #fff;
  }
}
.feedback_wrap {
  background-color: #000;
  border-radius: 30px;
  padding: 30px;
  flex: 1;
}

.feedback_input {
  background: rgba(255, 255, 255, 0.1);
  input {
    outline: none;
    background-color: transparent;
    border: none;
  }
  ::placeholder {
    color: rgba(256,256,256,0.5);
  }
  button {
    background-image: linear-gradient(30deg, #F7B500, #B620E0, #32C5FF);
    color: #fff;
    font-family: $ff;
    padding: 6px 26px;
  }
}

.feedback_item {
  vertical-align: top;
  display: inline-block;
}
.right_area {
  li > a {

    color: #fff;
  }
}
.lang_selector {
  margin-left: 30px;
  margin-right: 30px;
}
.footer_medium {
  display: flex;
  // flex-wrap: wrap;
  .medium_email {
    border-left: 1px solid #fff;
    padding-left: 20px;
  }
}


@media(max-width: 750px) {
  .left_area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer {
    width: 100%;
    display: block;
    padding: 30px 30px;
    text-align: center;
  }

  .lang_selector{
    display: inline-block;
    width: 20%;
  }

  :global(.dib2) {
    display: inline-block !important;
    width: 50%;
  }

  .right_area {
    margin-top: 20px;
    color: #fff;
  }
  .footer_medium {
    
    .email_text {
      display: none;
    }
  }
}