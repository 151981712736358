@import "~sacss";
@import "~sacss/mixin.scss";

.btn_reset {
  margin: 0;
  padding: 0;
  border: none 0;
  box-sizing: border-box;
  background-color: transparent;
  -webkit-appearance: none;
  font: inherit;
  cursor: pointer;
}

* {
  font-family: 'Poppins', sans-serif;
}
body {
  color: rgba(0,0,0,1) !important
}

@include Margins((2, 4, 6, 8, 10, 16, 20, 24, 28, 30, 36, 40, 50, 60), 1px);
@include Paddings((2, 4, 8, 10, 16, 20, 30,40),  1px);
@include SACSS(font-size, fs, (0, 12, 14, 16, 18, 20, 24, 32),  1px);
@include SACSS(line-height, lh, (16, 20, 24, 28, 32, 40, 48),  1px);
@include SACSS(font-weight, fw, (300, 500, 600, 700));
@include SACSS(border-radius, br, (2, 4, 8, 10, 12, 14, 16, 24, 30, 32),  1px);



.ell{ @include Ellipsis;  }
.ells{ @include Ellipsis-multiple;  }
.ells._2{ -webkit-line-clamp: 2; }

.br50\% {
  border-radius: 50%;
}
.dif {
  display: inline-flex;
}
.auto {
  margin-left: auto;
  margin-right: auto;
}
.c_l {
  color: rgba(0,0,0,1);
}
.c_m {
  color: rgba(0,0,0,0.6);
}
.c_s {
  color: rgba(0,0,0,0.3);
}
.w50\% {
  width: 50%;
}
.w100\% {
  width: 100%;
}
.h100\% {
  height: 100%;
}
svg:not(:root) {
  display: none;
}
.cp {
  cursor: pointer;
}