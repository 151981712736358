#container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dots {
  width: 0;
  height: 10px;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 100px auto 30px;
  filter: url(#goo);
}

.dot {
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.dot:before {
  content: "";
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background: #FBD301;
  position: absolute;
  left: 50%;
  transform: translateY(0) rotate(0deg);
  margin-left: -17.5px;
  margin-top: -17.5px;
}
@-webkit-keyframes dot-move {
  0% {
    transform: translateY(0);
  }
  18%, 22% {
    transform: translateY(-70px);
  }
  40%, 100% {
    transform: translateY(0);
  }
}
@keyframes dot-move {
  0% {
    transform: translateY(0);
  }
  18%, 22% {
    transform: translateY(-70px);
  }
  40%, 100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes dot-colors {
  0% {
    background-color: #FBD301;
  }
  25% {
    background-color: #FF3270;
  }
  50% {
    background-color: #208BF1;
  }
  75% {
    background-color: #AFE102;
  }
  100% {
    background-color: #FBD301;
  }
}
@keyframes dot-colors {
  0% {
    background-color: #FBD301;
  }
  25% {
    background-color: #FF3270;
  }
  50% {
    background-color: #208BF1;
  }
  75% {
    background-color: #AFE102;
  }
  100% {
    background-color: #FBD301;
  }
}
.dot:nth-child(5):before {
  z-index: 100;
  width: 45.5px;
  height: 45.5px;
  margin-left: -22.75px;
  margin-top: -22.75px;
  -webkit-animation: dot-colors 4s ease infinite;
          animation: dot-colors 4s ease infinite;
}
@-webkit-keyframes dot-rotate-1 {
  0% {
    transform: rotate(-105deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes dot-rotate-1 {
  0% {
    transform: rotate(-105deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.dot:nth-child(1) {
  -webkit-animation: dot-rotate-1 4s 0s linear infinite;
          animation: dot-rotate-1 4s 0s linear infinite;
}
.dot:nth-child(1):before {
  background-color: #FF3270;
  -webkit-animation: dot-move 4s 0s ease infinite;
          animation: dot-move 4s 0s ease infinite;
}
@-webkit-keyframes dot-rotate-2 {
  0% {
    transform: rotate(165deg);
  }
  100% {
    transform: rotate(540deg);
  }
}
@keyframes dot-rotate-2 {
  0% {
    transform: rotate(165deg);
  }
  100% {
    transform: rotate(540deg);
  }
}
.dot:nth-child(2) {
  -webkit-animation: dot-rotate-2 4s 1s linear infinite;
          animation: dot-rotate-2 4s 1s linear infinite;
}
.dot:nth-child(2):before {
  background-color: #208BF1;
  -webkit-animation: dot-move 4s 1s ease infinite;
          animation: dot-move 4s 1s ease infinite;
}
@-webkit-keyframes dot-rotate-3 {
  0% {
    transform: rotate(435deg);
  }
  100% {
    transform: rotate(810deg);
  }
}
@keyframes dot-rotate-3 {
  0% {
    transform: rotate(435deg);
  }
  100% {
    transform: rotate(810deg);
  }
}
.dot:nth-child(3) {
  -webkit-animation: dot-rotate-3 4s 2s linear infinite;
          animation: dot-rotate-3 4s 2s linear infinite;
}
.dot:nth-child(3):before {
  background-color: #AFE102;
  -webkit-animation: dot-move 4s 2s ease infinite;
          animation: dot-move 4s 2s ease infinite;
}
@-webkit-keyframes dot-rotate-4 {
  0% {
    transform: rotate(705deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
@keyframes dot-rotate-4 {
  0% {
    transform: rotate(705deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
.dot:nth-child(4) {
  -webkit-animation: dot-rotate-4 4s 3s linear infinite;
          animation: dot-rotate-4 4s 3s linear infinite;
}
.dot:nth-child(4):before {
  background-color: #FBD301;
  -webkit-animation: dot-move 4s 3s ease infinite;
          animation: dot-move 4s 3s ease infinite;
}