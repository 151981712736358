:root {
    --color: #5721B1;
    --text: color(#fff a(70%));
    --error: #f45959;
    --bg: #fff;
    --blue: blue;
    --border: color(#fad06a a(20%));
    --green: #86ba6e;
    --link: #fd7749;
    --red: #fa6a6a;
    --yellow: #fad06a;
    --primary: #5721B1;
    --yellowHover: #e4be62;
    --yellowDisabled: color(#fad06a a(30%));
    --transitionDefault: .3s;
  }

  $c_s: rgba(0, 0, 0, 0.3);
  $c_l: rgba(0, 0, 0, 1);
  $ff: 'Poppins', sans-serif;