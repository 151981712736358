@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  font-family: 'Poppins';
}

.container {
  background: linear-gradient(225deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
  width: 100%;
  justify-content: center;
  display: flex;
  padding-bottom: 90px;
}

.inner_container {
  width: 1200px;
  @media(max-width: 750px) {
    max-width: 750px;
  }
}

.concept_text {
  position: relative;
  top: 130px;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #fff;
}

.concept_text_small {
  font-size: 14px;
  color: #fff;
  opacity: 0.6;
}

.concept {
  width: 220px;
  height: 310px;
  margin-top: 160px;
  mix-blend-mode: normal;
  border: 3px solid rgba(255,255,255,0.1);
  box-sizing: border-box;
  border-radius: 30px;
  display: inline-block;
  margin-right: 100px;
  text-align: center;
  vertical-align: bottom;
}

.concept_last {
  width: 220px;
  height: 310px;
  margin-top: 160px;
  mix-blend-mode: normal;
  border: 3px solid rgba(255,255,255,0.1);
  box-sizing: border-box;
  border-radius: 30px;
  display: inline-block;
  text-align: center;
  vertical-align: bottom;
}

.concept:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  .arrow {
    opacity: 1;
  }
}

.concept_header {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 80px;
  font-weight: 700;
  color: #fff;
  position: relative;
  top: -40px;
  left: -40px;
}

.concept_last:hover {
  background-color: rgba(255, 255, 255, 0.3);
  .arrow {
    opacity: 1;
  }
}

.selector_img {
  width: 60px;
  margin-top: -50px;
}

.arrow {
  width: 26px;
  opacity: 0.1;
}

.arrow_wrapper {
  margin-top: 80px;
}