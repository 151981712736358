@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  font-family: 'Poppins';
}

.roadmap {
  width: 100%;
  // background-color: #000;
  margin-top: 20px;
}
.partners {
  width: 100%;
  // background-color: #eee;
  text-align: center;
  color: #000;
}

.roadmap_body, .partners_body {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding: 60px 0;
}
.roadmap_body {
  // color: #fff;
  text-align: left;
}

.main_b {
  width: 100%;
  background-color: #000;
  color: #fff;
}
.main_c {
  // width: 100%;
  color: #fff;
  // background: linear-gradient(225deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
  background: linear-gradient(226.11deg, #5A50D0 0%, #5D4ADD 11.46%, #9F43D7 41.46%, #CF5A7C 66.48%, #EC811E 83.97%, #FFA858 97.92%);
  border-radius: 20px;
  transform: rotate(-180deg);
}
.main_g {
  width: 100%;
  background-color: #171717;
  color: #fff;
}
.main_intro {
  margin-top: 80px;
}
.main_body {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 80px;
}
.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row_title {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
}

.row_subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}
.row_title_w {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 1.5;
  margin: 130px 0 50px;
}
.col_box {
  padding: 20px 30px;
  background: rgba(251, 251, 251, 0.6);
  border: 1px solid #DEDEDE;
  backdrop-filter: blur(34px);
  border-radius: 10px;
}

.col_12_w {
  width: 45%;
  .cover {
    width: 100%;
  }
  .img {
    position: relative;
    width: 100px;
    height: 100px;
    left: -14px;
  }
  h2 {
    color: #fff;
    font-size: 20px;
    margin: 30px 0;
  }
  p {
    font-size: 16px;
  }
}
.row_p_w {
  font-size: 16px;
  opacity: 0.6;
  margin-bottom: 190px;
}
.c_img {
  width: 260px;
}
.g_box {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding: 40px;
  margin-top: 50px;
}

.welcome_text {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  mix-blend-mode: normal;
  opacity: 0.3;
}

.feature {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  margin-top: 10px;
  // margin-bottom: 20px;
}

.roadmap_box {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  li {
    width: 25%;
  }
}

.dot_box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    display: inline-block !important;
  }
}
.dot_bg {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(215.51deg, rgba(80, 195, 254, 0.1) 9.68%, rgba(112, 218, 254, 0.1) 31.97%, rgba(147, 165, 253, 0.1) 60.35%, rgba(204, 160, 215, 0.1) 91.12%)
}
.dot {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: linear-gradient(225deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
}

.line {
  flex: 1;
  border-bottom: 2px dashed rgba(0, 0, 0, 1);
}

.roadmap_title {
  font-size: 20px;
  font-weight: bold;
  margin: 30px 0 10px;
}

.roadmap_text {
  padding-right: 30px;
  font-size: 14px;
  // opacity: 0.5;
  display: flex;
  flex-direction: column;
  list-style: dot;
  li {
    list-style: disc;
    width: 100%;
    margin-bottom: 10px;
  }
}

.logo_box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  div {
    width: 33.3333333%;
    text-align: center;
    line-height: 100px;
    overflow: hidden;
  }
  img {
    width: 150px;
  }
}

.bd_img {
  border-radius: 5px;
  padding: 10px;
  background: #333;
}
.row_three_title {
  font-size: 18px;
  font-weight: 700;
}
.nftplus {
  img{
    width: 100%;
    padding: 30px;
  }
  .nftPlus_box {
    padding: 30px 80px;
  }
  .col_box {
    font-size: 16px;
    line-height: 26px;
  }
  .fm20 {
    margin-left: -30px;
  }
}
.introImg {
  width: 90%;
}
.marketplace_module {
  max-width: 1320px;
  transform: rotate(-180deg);
  padding: 30px 20px;
  font-size: 16px;
  .content_box {
    margin-top: 40px;
    padding: 0 50px;

  }
}
.pl40_h5 {
  padding-left: 40px;
}
.ml10_h5 {
  margin-left: 10px;
}

@media(max-width: 750px) {
  .main_intro {
    margin-top: 40px;
  }
  .main_body {
    padding: 0 20px;
    margin-bottom: 40px;
  }
  .row_title {
    font-size: 30px;
    line-height: 40px;
  }
  .title_sp {
    margin-right: -10px;
    margin-bottom: 20px;
  }
  .col_12_w {
    width: 100%;
    h2 {
      margin: 0 0 15px;
    }
  }
  .col_box {
    padding: 20px;
  }
  .col1 {
    order: 1;
  }
  .col2 {
    order: 0;
    text-align: center;
  }
  .col3 {
    order: 2;
    text-align: center;
  }
  .col4 {
    order: 3;
  }
  .col5 {
    order: 5;
  }
  .col6 {
    order: 4;
    text-align: center;
  }
  .row_p_w {
    margin-bottom: 50px;
  }
  .nftplus {
    img{
      width: 100%;
      padding: 30px;
    }
    .nftPlus_box {
      padding: 0;
    }
    .fm20 {
      margin-left: 0px;
    }
  }
  .pl40_h5 {
    padding-left: 0px;
  }
  .ml10_h5 {
    margin-left: 0px;
  }
  .introImg {
    width: 100%;
  }
  .feature {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 30px;
  }
  .marketplace_module {
   
    .content_box {
      margin-top: 40px;
      padding: 0 20px;
    }
  }

  .roadmap_body, .partners_body {
    padding: 40px 0;
  }

  .roadmap_box {
    display: flex;
    flex-direction: column;
    li {
      position: relative;
      width: 100%;
    }
    .roadmap_title, .roadmap_text {
      margin-left: 100px;
    }
    .roadmap_title {
      margin-top: 10px;
    }
    .roadmap_text {
      padding-bottom: 20px;
    }
    .dot_box {
      position: absolute;
      height: 100%;
      flex-direction: column;
      left: 20px;
      svg {
        display: inline-block !important;
      }
    }
    .line {
      border-bottom: none;
      border-left: 2px dashed rgba(0, 0, 0, 1);
    }
  }
  .feature {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  margin-top: 10px;
  // margin-bottom: 20px;
}
  .logo_box {
    div {
      width: 50%;
    }
  }
}