@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.container {
  display: block;
  max-width: 1200px;
  @media(max-width: 750px) {
    width: 100%;
  }
}
.common_dao {
  background: linear-gradient(103.72deg, #F8F2FF 0.48%, rgba(255, 247, 235, 0.572917) 74.32%, rgba(255, 255, 255, 0) 99.09%);
  border-radius: 10px;
  padding: 60px 40px;
}
.common_row {
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .common_title {
    color: #000;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 6px;
  }
}

.common_content {
  font-size: 18px;
}
.common_col_12 {
  width: 50%;
}
.common_col_8 {
  width: 30%;
  margin-bottom: 30px;

  h2 {
    color: #000;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
  }
}
.col_t1 {
  width: 150px;
}
.col_t2 {
  width: 200px;
}

.web30_module {
  margin-top: 30px;
  background: linear-gradient(90deg, #000000 0%, #3A3A3A 100%);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-right: -30px;
}
.common_web30 {
  position: absolute;
  top: -20px;
  left: -20px;
  bottom: -20px;
  right: 10px;
  z-index: 9;
  padding: 80px 70px 80px 60px;
  background: linear-gradient(90deg, #000000 0%, #3A3A3A 100%);
  border-radius: 30px 8px 8px 30px;
  color: #fff;

  .common_title {
    line-height: 48px;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
.common_web30_box {
  height: 490px;
  padding-left: 40px;
  background: linear-gradient(90deg, #000000 0%, #3A3A3A 100%);
  border-radius: 0px 30px 30px 0px;
  color: #fff;
}
.common_web30_content {
  padding: 60px 20px ;
  color: #fff;
  .common_title {
    line-height: 28px;
    font-size: 24px;
    font-weight: 600;
    margin: 20px 0;
  }

}
.common_web30_content:last-child {
  padding-right: 40px;
}


@media(max-width: 750px) {
  .common_dao {
    padding: 40px 20px;
  }
  .common_content {
    font-size: 16px;
  }
  .dao_item {
    margin-top: 20px;
  }
  .web30_module {
    padding-bottom: 20px;
    margin-bottom: 40px;
    margin-right: 0;
    border-radius: 0;
  }
  .common_web30 {
    position: static;
    top: 0;
    left: 0;
    padding: 40px 30px 20px 30px;
    width: 100%;
    height: 300px;
    .common_title {
      line-height: 40px;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
  .common_web30_content {
    padding: 30px;
  }
  .common_row {
    margin-bottom: 10px;
    padding: 0 10px;
    .common_title {
      font-size: 25px;
      line-height: 1.4;
    }
    h2 {
      font-size: 20px;
    }
    .common_col_8 {
      width: 100%;
    }
    .col_t1, .col_t2 {
      width: 100%;
    }
  }
  .add_margin {
    margin-right: 0;
  }
}